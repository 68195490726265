<template>
  <div id="box">
    <h1>进入量表评估系统</h1>
    <el-button type="success" @click="goToAssessment">点击进入</el-button>
  </div>
</template>

<script>
export default {
  methods: {
    goToAssessment() {
      this.$router.push("/AssessmentLogin");
    },
  },
};
</script>

<style lang='scss' scoped>
#box {
  width: 300px;
  height: 400px;
  border-radius: 20px;
  background: rgb(231, 236, 150);
  margin-left: 200px;
  opacity: 0.7;
  line-height: 100px;
  text-align: center;
}
</style>
